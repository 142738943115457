import {onFind} from "@elements/init-modules-in-scope";
import {on, findIn, closest} from "@elements/dom-utils";
import * as formValidation from '@elements/form-validation';

export function init () {
    onFind('.js-password-repeat', (password) => {
        let passwordInput = findIn('input', password);
        let form = closest('form', password);
        let repeatGroup = findIn('.js-password-repeat__repeat', form);
        let repeatInput = findIn('input', repeatGroup);
        let formInstance;
        let options = {
            validators: {
                identical: {
                    enabled: true
                }
            }
        }

        formValidation.getApi(form).then((formApi) => {

            formInstance = formApi.getFormInstance();
            formInstance.setFieldOptions(repeatInput.getAttribute('name'), options);

            on('change', (e) => {
                formInstance
                    .updateValidatorOption(repeatInput.getAttribute('name'), 'identical', 'compare', passwordInput.value)
                    .updateValidatorOption(repeatInput.getAttribute('name'), 'identical', 'message', repeatInput.getAttribute('data-error-message') ? repeatInput.getAttribute('data-error-message') : '')
                    .revalidateField(repeatInput.getAttribute('name'));
            }, passwordInput);
        });
    })
}