import {findIn, addClass, removeClass, on, hasClass} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";
import * as zxcvbn from "zxcvbn";
import * as formValidation from '@elements/form-validation';


export function init () {
    onFind('.js-password-strength-form', function (form) {
        let formInstance;
        let passwordInput = findIn('.js-password-strength-form__input', form);
        let passwordStrengthIndicator = findIn('.js-password-strength-meter', form);
        let passwordStrengthText = findIn('.js-password-strength-text', form);
        let passwordStrengthTextWeak = passwordStrengthIndicator.dataset.passwordStrengthTextWeak;
        let passwordStrengthTextStrong = passwordStrengthIndicator.dataset.passwordStrengthTextStrong;
        let options = {
            validators: {
                checkPassword: {
                    enabled: true
                },
                notEmpty: {
                    message: 'The full name is required',
                },
            }
        }

        formValidation.getApi(form).then((formApi) => {
            formInstance = formApi.getFormInstance();
            formInstance.setFieldOptions(passwordInput.getAttribute('name'), options);

            formInstance
                // Register the validator
                .registerValidator('checkPassword', strongPassword);
        });

        on('keyup', function () {
            if(passwordStrengthIndicator.hasAttribute("hidden")) {
                passwordStrengthIndicator.removeAttribute("hidden");
            }
            if(hasClass('is-valid', passwordInput)) {
                removeClass('is-invalid', passwordStrengthIndicator);
                passwordStrengthText.innerHTML = passwordStrengthTextStrong;
            } else if(hasClass('is-valid', passwordInput) && passwordInput.value === '') {
                removeClass('is-valid', passwordInput);
                addClass('is-invalid', passwordInput);
            } else {
                addClass('is-invalid', passwordStrengthIndicator);
                passwordStrengthText.innerHTML = passwordStrengthTextWeak;
            }
        }, passwordInput);
    });
}

const strongPassword = function () {
    return {
        validate: function (input) {
            const value = input.value;
            if (value === '' || value === null) {
                return {
                    valid: true,
                };
            }

            const result = zxcvbn(value);
            const score = result.score;
            const message = result.feedback.warning || 'The password is weak';

            // By default, the password is treat as invalid if the score is smaller than 3
            // We allow user to change this number via options.minimalScore
            const minimalScore = input.options && input.options.minimalScore ? input.options.minimalScore : 3;
            if (score < minimalScore) {
                return {
                    valid: false,
                    message: message,
                    meta: {
                        score: score
                    },
                }
            } else {
                return {
                    valid: true,
                }
            }
        },
    };
};